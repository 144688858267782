<script setup lang="ts">
import { Link, usePage } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'

const page = usePage()
const { t } = useI18n()

const goldCategory = page.props.layout.categories.find((category) => category.code === 'gold-jewellery')
const silverCategory = page.props.layout.categories.find((category) => category.code === 'silver-jewellery')
</script>

<template>
    <section class="grid h-[700px] grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1">
        <div class="relative flex">
            <template v-if="goldCategory">
                <picture>
                    <source data-srcset="/images/sections/category-gold.webp" type="image/webp">
                    <source data-srcset="/images/sections/category-gold.jpeg" type="image/jpeg">

                    <img data-src="/images/sections/category-gold.jpeg" :alt="goldCategory.name" class="absolute inset-0 object-cover object-center w-full h-full lazyload">
                </picture>

                <div class="relative flex flex-col items-start justify-end w-full p-8 bg-black bg-opacity-40 sm:p-12">
                    <Link class="group" :href="route('shop.category', goldCategory.slug_path)">
                    <h3 class="mb-1 text-2xl font-medium text-white">{{ goldCategory.name }}</h3>

                    <div class="text-sm font-medium text-gray-300 transition-colors group-hover:text-gray-200">
                        {{ t('pages.home.metal-categories.shop-now') }}
                        <span class="inline-block transition duration-200 ease-in-out group-hover:translate-x-1" aria-hidden="true"> &rarr;</span>
                    </div>
                    </Link>
                </div>
            </template>
        </div>

        <div class="relative flex">
            <template v-if="silverCategory">
                <picture>
                    <source data-srcset="/images/sections/category-silver.webp" type="image/webp">
                    <source data-srcset="/images/sections/category-silver.jpeg" type="image/jpeg">

                    <img data-src="/images/sections/category-silver.jpeg" :alt="silverCategory.name" class="absolute inset-0 object-cover object-center w-full h-full lazyload">
                </picture>

                <div class="relative flex flex-col items-start justify-end w-full p-8 bg-black bg-opacity-40 sm:p-12">
                    <Link class="group" :href="route('shop.category', silverCategory.slug_path)">
                    <h3 class="mb-1 text-2xl font-medium text-white">{{ silverCategory.name }}</h3>

                    <div class="text-sm font-medium text-gray-300 transition-colors group-hover:text-gray-200">
                        {{ t('pages.home.metal-categories.shop-now') }}
                        <span class="inline-block transition duration-200 ease-in-out group-hover:translate-x-1" aria-hidden="true"> &rarr;</span>
                    </div>
                    </Link>
                </div>
            </template>
        </div>
    </section>
</template>
